<mat-dialog-content class="adjust-dialog">
  <h2 class="mb-5"
      mat-dialog-title>{{ getDialogTitle() }}</h2>
  <form [formGroup]="form"
        autocomplete="off">
    <!-- Tipo de Mensagem -->
    <mat-form-field class="col-6 mb-2">
      <mat-label>Tipo de Mensagem</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let option of typeOptions"
                    [value]="option.id"
                    (click)="changedTypeOption(option)">{{ option.label }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('type').invalid">Campo obrigatório</mat-error>
    </mat-form-field>

    <!-- Horário -->
    <mat-form-field class="col-6"
                    *ngIf="showTimeSelector">
      <mat-label>Horário</mat-label>
      <mat-select formControlName="time">
        <mat-option *ngFor="let hour of hours"
                    [value]="hour">{{ hour }}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('time').invalid">Campo obrigatório</mat-error>
    </mat-form-field>

    <!-- Dias após fechamento -->
    <mat-form-field class="col-3 mb-2"
                    *ngIf="isPosVenda">
      <mat-label>Os fechada a</mat-label>
      <input matInput
             type="number"
             formControlName="osDay">
      <mat-error *ngIf="form.get('osDay').invalid">Campo obrigatório</mat-error>
    </mat-form-field>

    <!-- Dias antes do vencimento -->
    <mat-form-field class="col-12 mb-2"
                    *ngIf="showDueTitleMessage">
      <mat-label>Dias antes do vencimento</mat-label>
      <input matInput
             type="number"
             formControlName="titleMessageDay">
      <mat-error *ngIf="form.get('titleMessageDay').invalid">Campo obrigatório</mat-error>
    </mat-form-field>

    <!-- Mensagem -->

    @if (!isLaborWarningMessage && !showCashFlowMessage) {
    <mat-form-field class="mat-form-field col-12 d-flex flex-column mb-2">
      <mat-label>Mensagem @if (showDueTitleMessage) { (Essa mensagem será enviada também para clientes) }</mat-label>
      <input matInput
             maxlength="300"
             class="mt-2"
             formControlName="message"
             #observationInput>
      <button mat-icon-button
              class="emoji-button position-absolute"
              (click)="toggleEmojiPicker(); $event.stopPropagation();">
        😊 <!-- Ícone do botão do emoji, você pode usar outro emoji aqui -->
      </button>
      <mat-error *ngIf="form.get('message').invalid">Campo obrigatório</mat-error>
    </mat-form-field>
    }

    <div class="ajust-emoji"
         *ngIf="showEmojiPicker">
      <emoji-mart (emojiClick)="addEmoji($event)"></emoji-mart>

    </div>

    <!-- Adicione isso próximo ao campo de mensagem -->
    <div *ngIf="showAvailableVariables"
         class="available-variables">
      <h4>Utilize as seguintes palavras-chave:</h4>
      <div class="variables-list">
        <div *ngFor="let variable of availableVariables"
             class="variable-item"
             (click)="insertVariable(variable.key)">
          <strong>{{variable.key}}</strong> - {{variable.description}}
        </div><br>

        <p>{{exampleMessage}}</p>
      </div>
    </div>

    <div *ngIf="showCashFlowMessage || showDueTitleMessage"
         class="col-12 mb-2">
      <mat-form-field class="w-100">
        <mat-label>Buscar destinatários</mat-label>
        <input matInput
               [formControlName]="'recipientSearch'"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete"
                          (optionSelected)="selectUser($event.option.value)">
          @for (user of filteredUsers(); track user._id) {
          <mat-option [value]="user">
            {{user.name}}
            <small class="text-muted"> - {{user.email}}</small>
          </mat-option>
          }
        </mat-autocomplete>
        <mat-error *ngIf="form.get('recipients').invalid">Destinatários obrigatórios</mat-error>
      </mat-form-field>

      <!-- Lista de destinatários selecionados -->
      <mat-chip-list *ngIf="selectedRecipients().length">
        <mat-label>Destinatários: </mat-label>
        @for (user of selectedRecipients(); track user.id) {
        <mat-chip (removed)="removeUser(user)"
                  class="mr-2">
          {{user.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        }
      </mat-chip-list>
    </div>

    <!-- Legendas de Ajuda -->
    <div *ngIf="!showTimeSelector"
         class="help-text">
      <h4>Utilize as seguintes palavras-chave:</h4>
      <p>
        <strong>{{'{'}}{{'{'}} os {{'}}'}}</strong> = número OS, <br>
        <strong>{{'{'}}{{'{'}} plate {{'}}'}}</strong> = número da placa, <br>
        <strong>{{'{'}}{{'{'}} company {{'}}'}}</strong> = nome empresa, <br>
        <strong>{{'{'}}{{'{'}} client {{'}}'}}</strong> = Nome Cliente, <br>
        <strong>{{'{'}}{{'{'}} budget {{'}}'}}</strong> = Número Orçamento, <br>
        <strong>{{'{'}}{{'{'}} consultor {{'}}'}}</strong> = Nome do Consultor.
      </p>
      <p>Exemplo de mensagem: Uma nova OS {{'{'}}{{'{'}} os {{'}}'}} para a placa {{'{'}}{{'{'}} plate {{'}}'}} em
        {{'{'}}{{'{'}} company {{'}}'}} acaba de ser fechada.</p>
    </div>


    <!-- Botões de Ação -->
    <mat-dialog-actions>
      <button mat-raised-button
              color="warn"
              [mat-dialog-close]="false">Cancelar</button>
      <button mat-raised-button
              color="primary"
              (click)="save()"
              [disabled]="form.invalid">Salvar</button>
      <button mat-raised-button
              color="accent"
              (click)="deleteMessage()"
              *ngIf="form.get('_id').value && (profile | canAccess: 'RECORDS.controlMessages.delete')">Excluir</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>


<!-- Container para o Seletor de Emojis -->