import { Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { ControlMessageService } from 'src/app/service/control-message.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { ControlMessage } from 'src/app/interface/control-message';
import { ControlMessageType } from 'src/app/interface/enum-controlMessages-type';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { LayoutService } from 'src/app/service/layout.service';
import { AccessProfile } from '../../page/company/access-profile/models/access-profile';
import { UserService } from 'src/app/service/user.service';
import { MkgUser } from 'src/app/interface/user';

@Component({
  selector: 'app-control-message-edit',
  templateUrl: './control-message-edit.component.html',
  styleUrls: ['./control-message-edit.component.scss']
})
export class ControlMessageEditComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @ViewChild("observationInput") observationInput: ElementRef<HTMLInputElement>

  form: FormGroup;
  hours: string[] = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  showEmojiPicker: boolean = false;
  selectedEmoji: EmojiData | undefined;

  showTimeSelector: boolean = false;
  isLaborWarningMessage: boolean = false;
  isPosVenda: boolean = false;
  showDueTitleMessage: boolean = false;
  showCashFlowMessage: boolean = false;
  // Tipos de mensagem que exibem o seletor de horário
  timeSelectorTypes: ControlMessageType[] = [
    ControlMessageType.Aniversariante,
    ControlMessageType.PosVenda,
    ControlMessageType.LaborWarningMessage,
    ControlMessageType.DueTitleMessage,
    ControlMessageType.CashFlow
  ];

  // Definindo opções estáticas para o campo type
  typeOptions: ITypeOption[] = [
    { id: ControlMessageType.Aniversariante, label: 'Aniversariante' },
    { id: ControlMessageType.AbrirOrcamento, label: 'Abrir orçamento' },
    { id: ControlMessageType.SolicitacaoAtualizacao, label: 'Solicitação de autorização' },
    { id: ControlMessageType.AbrirOS, label: 'Abrir os' },
    { id: ControlMessageType.FecharOS, label: 'Fechar os' },
    { id: ControlMessageType.PesquisaDeSatisfacao, label: 'Pesquisa de Satisfação' },
    { id: ControlMessageType.DueTitleMessage, label: "Vencimento de título" },
    { id: ControlMessageType.CashFlow, label: "Fluxo de caixa" }
  ];

  profile: AccessProfile

  availableVariables: { key: string; description: string; }[] = [];
  showAvailableVariables: boolean = false;
  exampleMessage: string = '';
  filteredUsers = signal<MkgUser[]>([]);
  selectedRecipients = signal<MkgUser[]>([]);

  private readonly messageTemplates = {
    [ControlMessageType.Aniversariante]: {
      variables: [
        { key: '{{nome}}', description: 'Nome do cliente' },
        { key: '{{empresa}}', description: 'Nome da empresa' },
        { key: '{{telefone}}', description: 'Telefone da empresa' }
      ],
      example: 'Feliz aniversário {{nome}}! A equipe da {{empresa}} deseja muitas felicidades! Em caso de dúvidas, entre em contato conosco: {{telefone}}'
    },
    [ControlMessageType.DueTitleMessage]: {
      variables: [
        { key: '{{nome}}', description: 'Nome do usuário' },
        { key: '{{empresa}}', description: 'Nome da empresa' },
        { key: '{{titulos}}', description: 'Lista de títulos com número, valor e vencimento' }
      ],
      example: 'Olá {{nome}}, os seguintes títulos vencerão em 3 dias:\n\n{{titulos}}'
    }
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { event?: ControlMessage, profile?: AccessProfile } | null,
    private fb: FormBuilder,
    private controlMessageService: ControlMessageService,
    private dialogRef: MatDialogRef<ControlMessageEditComponent>,
    private layoutService: LayoutService,
    private snackbar: SnackbarService,
    private userService: UserService
  ) {
    // Inicializa o formulário com os dados da mensagem recebida
    this.form = this.fb.group({
      _id: [data?.event?._id],
      type: [data?.event?.type || undefined, Validators.required],
      message: [data?.event?.message || undefined],
      time: [data?.event?.time || undefined],
      osDay: [data?.event?.osDay || undefined],
      titleMessageDay: [data?.event?.titleMessageDay || undefined],
      recipients: [[]],
      recipientSearch: ['']
    });

    if (data?.event?._id) {
      this.changedTypeOption({ id: data?.event?.type, label: "" });
    }

    if (data.profile) {
      this.profile = data.profile;
    }

    dialogRef.backdropClick().pipe(takeUntil(this._destroy$)).subscribe(() => {
      dialogRef.close();
    })
  }

  ngOnInit() {
    if (this.data.event) {
      const objeto = this.data.event
      this.form.patchValue(objeto);
    }

    if (this.data?.event?.recipients?.length) {
      this.form.get('recipients').setValue(this.data.event.recipients.map(recipient => recipient._id));
      this.selectedRecipients.set(this.data.event.recipients);
    }

    this.form.get('recipientSearch').valueChanges.pipe(
      takeUntil(this._destroy$),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(search => this.userService.searchUsers(search))
    ).subscribe(users => {
      this.filteredUsers.set(users);
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      if (this.showEmojiPicker) {
        this.showEmojiPicker = false;
        this.observationInput.nativeElement.focus();
      } else {
        this.dialogRef.close();
      }
    }
  }

  // Salva a mensagem ao fechar o diálogo
  async save(): Promise<void> {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      // Converte emojis para Unicode antes de salvar
      formValue.message = this.isLaborWarningMessage ? undefined : this.toUnicode(formValue.message);
      try {
        await this.controlMessageService.saveMessage(
          {
            ...formValue,
            _id: this.data?.event?._id || undefined
          }
        );
        this.dialogRef.close(formValue);
      } catch (error) {
        console.error('Erro ao salvar a mensagem', error);
        this.snackbar.error('CONTROL_MESSAGE.ERROR_WHILE_SAVE_MESSAGE');
      }
    } else {
      this.snackbar.error('CONTROL_MESSAGE.FILL_REQUIRED_FIELDS');
    }
  }

  deleteMessage(): void {
    this.layoutService.loader = true;

    const messageId = this.form.get('_id').value;
    this.controlMessageService.deleteMessage(messageId).then(() => {
      this.dialogRef.close(true);
      this.layoutService.loader = false;
    }).catch(error => {
      console.error('Erro ao excluir a mensagem', error);
      this.snackbar.error('Erro ao excluir a mensagem');
    });
  }
  // Retorna o rótulo do tipo com base no ID
  getMessageTypeLabel(type: ControlMessageType): string {
    const selectedType = this.typeOptions.find(option => option.id === type);
    return selectedType ? selectedType.label : '';
  }

  // Retorna o título do diálogo com base no tipo selecionado
  getDialogTitle(): string {
    const type = this.form.get('type').value;
    if (type) {
      return this.getMessageTypeLabel(type);
    } else {
      return 'Mensagem WhatsApp';
    }
  }

  changedTypeOption(event: ITypeOption) {
    this.setShowTimeSelector(event.id);
    this.setIsPosVenda(event.id);
    this.setShowMessageInput(event.id);
    this.setAvailableVariables(event.id);
    this.setShowDueTitleMessage(event.id);
    this.setShowCashFlowMessage(event.id);
    this.setRequiredFields(event.id);
  }

  // Verifica se deve exibir o seletor de horário
  setShowTimeSelector(typeValue: ControlMessageType) {
    this.showTimeSelector = this.timeSelectorTypes.includes(typeValue);
  }

  setShowDueTitleMessage(typeValue: ControlMessageType) {
    this.showDueTitleMessage = typeValue === ControlMessageType.DueTitleMessage;
  }

  setShowCashFlowMessage(typeValue: ControlMessageType) {
    this.showCashFlowMessage = typeValue === ControlMessageType.CashFlow;
  }

  // Verifica se deve exibir o campo de dias após fechamento
  setIsPosVenda(typeValue: ControlMessageType) {
    this.isPosVenda = typeValue === ControlMessageType.PosVenda;

    const messageControl = this.form.get('message');
    const osDay = this.form.get("osDay");

    messageControl.setValue(undefined);
    osDay.setValue(undefined);
  }

  setShowMessageInput(typeValue: ControlMessageType) {
    const isLaborWarningMessage = typeValue === ControlMessageType.LaborWarningMessage;
    this.isLaborWarningMessage = isLaborWarningMessage;
    this.handleLaborWarningMessage(isLaborWarningMessage);
  }

  handleLaborWarningMessage(isLaborWarningMessage: boolean) {
    const messageControl = this.form.get('message');
    const time = this.form.get("time");

    messageControl.setValue(undefined);
    time.setValue(undefined);
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  private toUnicode(value: string): string {
    if (!value) return;
    return value.split('').map(char => '\\u' + char.charCodeAt(0).toString(16).padStart(4, '0')).join('');
  }

  addEmoji($event: any): void {
    this.selectedEmoji = $event.emoji;

    const currentMessage = this.form.get('message').value ?? "";

    this.form.get('message').setValue(currentMessage + $event.emoji.native);
    this.observationInput.nativeElement.focus();
    this.toggleEmojiPicker();
  }

  private setAvailableVariables(type: ControlMessageType): void {
    const template = this.messageTemplates[type];

    this.availableVariables = template?.variables || [];
    this.showAvailableVariables = Boolean(this.availableVariables.length);
    this.exampleMessage = template?.example || '';
  }

  insertVariable(variable: string): void {
    const messageControl = this.form.get('message');
    const currentValue = messageControl.value || '';
    const cursorPosition = this.observationInput.nativeElement.selectionStart;

    const newValue = currentValue.slice(0, cursorPosition) +
      variable +
      currentValue.slice(cursorPosition);

    messageControl.setValue(newValue);
  }

  selectUser(user: MkgUser): void {
    const currentRecipients = this.selectedRecipients();
    if (!currentRecipients.some(r => r._id === user._id)) {
      this.selectedRecipients.set([...currentRecipients, user]);
      this.form.patchValue({
        recipients: [...currentRecipients, user]
      });
    }
    this.form.get('recipientSearch').setValue('');
  }

  removeUser(user: MkgUser): void {
    const currentRecipients = this.selectedRecipients();
    this.selectedRecipients.set(currentRecipients.filter(r => r._id !== user._id));
    this.form.patchValue({
      recipients: currentRecipients.filter(r => r._id !== user._id)
    });
  }

  /**
   * Configura os campos obrigatórios baseado no tipo de mensagem
   */
  private setRequiredFields(type: ControlMessageType): void {
    const messageControl = this.form.get('message');
    const timeControl = this.form.get('time');
    const titleMessageDayControl = this.form.get('titleMessageDay');
    const recipientsControl = this.form.get('recipients');

    // Primeiro limpa todos os validators
    messageControl.clearValidators();
    timeControl.clearValidators();
    titleMessageDayControl.clearValidators();
    recipientsControl.clearValidators();

    // Configura os validators baseado no tipo
    switch (type) {
      case ControlMessageType.DueTitleMessage:
        messageControl.addValidators([Validators.required]);
        titleMessageDayControl.addValidators([Validators.required]);
        recipientsControl.addValidators([Validators.required]);
        timeControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.Aniversariante:
        messageControl.addValidators([Validators.required]);
        timeControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.AbrirOS:
        messageControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.FecharOS:
        messageControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.PesquisaDeSatisfacao:
        messageControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.CashFlow:
        timeControl.addValidators([Validators.required]);
        recipientsControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.SolicitacaoAtualizacao:
        messageControl.addValidators([Validators.required]);
        break;

      case ControlMessageType.AbrirOrcamento:
        messageControl.addValidators([Validators.required]);
        break;

      default:
        break;
    }

    // Atualiza o estado dos controles
    messageControl.updateValueAndValidity();
    timeControl.updateValueAndValidity();
    titleMessageDayControl.updateValueAndValidity();
    recipientsControl.updateValueAndValidity();
  }

}

interface ITypeOption {
  id: ControlMessageType;
  label: string;
}
