<h1 matDialogTitle>{{ form.value._id ? 'Atualizar' : 'Registrar' }} pacote de revisão</h1>
<mat-dialog-content>

    <form [formGroup]="form">
        <!-- name -->
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Nome</mat-label>
            <input matInput
                   formControlName="name"
                   maxlength="20">
        </mat-form-field>

        <!-- number -->
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Número</mat-label>
            <input matInput
                   formControlName="number"
                   maxlength="14">
        </mat-form-field>

        <!-- laborDefault -->
        <!-- <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Serviço padão</mat-label>
            <input matInput
                   formControlName="laborDefault"
                   #laborDefaultinput
                   [matAutocomplete]="laborDefaultAutocomplete">
            <mat-autocomplete #laborDefaultAutocomplete="matAutocomplete"
                              [displayWith]="allItems | displayWith: 'id' : 'description' ">
                <mat-option *ngFor="let item of allItems"
                            [value]="item.id">
                    {{ item.description }}
                </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="!allItems.length">
                <small>Sem itens registrados</small>
            </mat-hint>
        </mat-form-field> -->

        <!-- factoryCode -->
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Código de fábrica</mat-label>
            <input matInput
                   formControlName="factoryCode"
                   maxlength="14">
        </mat-form-field>

        <!-- code -->
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Código</mat-label>
            <input matInput
                   formControlName="code"
                   maxlength="14">
        </mat-form-field>

        <!-- qtyInstallments
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Máximo parcelas</mat-label>
            <input matInput
                   money
                   [decimalPoints]="0"
                   [limit]="60"
                   formControlName="qtyInstallments">
        </mat-form-field> -->

        <!-- standardInstallment
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Nº predefinido de parcelas</mat-label>
            <input matInput
                   money
                   [decimalPoints]="0"
                   [limit]="form.value.qtyInstallments"
                   formControlName="standardInstallment">
        </mat-form-field> -->

        <!-- interestAfter 
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Juros após parcela</mat-label>
            <input matInput
                   money
                   [decimalPoints]="0"
                   [limit]="form.value.qtyInstallments"
                   formControlName="interestAfter">
        </mat-form-field> -->

        <!-- fees
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Percentual de juros</mat-label>
            <input matInput
                   money
                   [decimalPoints]="3"
                   [limit]="100"
                   formControlName="fees">
            <span matSuffix>%</span>
        </mat-form-field> -->

        <!-- value 
        <mat-form-field class="col-12 col-md-4 col-lg-3">
            <mat-label>Valor do pacote</mat-label>
            <input matInput
                   money
                   formControlName="value">
        </mat-form-field> -->
    </form>

    <table mat-table
           cdkDropList
           (cdkDropListDropped)="drop($event)"
           cdkDropListData="dataSource"
           [dataSource]="itemsTable">

        <ng-container matColumnDef="default">
            <th mat-header-cell
                *matHeaderCellDef
                title="indique qual dos itens é a mão de obra padrão do pacote">
                Mão de obra
            </th>
            <td mat-cell
                *matCellDef="let row">
                <mat-checkbox [checked]="row.idLaborOrPart === form.value.laborDefault"
                              (change)="setLaborDefault(row)"
                              color="primary"></mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="idLaborOrPart">
            <th mat-header-cell
                *matHeaderCellDef> Item </th>
            <td mat-cell
                *matCellDef="let row"
                style="cursor: move">
                {{ allItems | findPropertyByKey : 'id' : row.idLaborOrPart : 'description' }}
            </td>
        </ng-container>

        <!-- MOCode -->
        <ng-container matColumnDef="MOCode">
            <th mat-header-cell
                *matHeaderCellDef
                class="txt-center"> Cód. / mão de obra </th>
            <td mat-cell
                *matCellDef="let row"
                class="txt-center">
                <input mkg-editable
                       matInput
                       maxlength="14"
                       [(ngModel)]="row.MOCode">
            </td>
        </ng-container>

        <!-- qty -->
        <ng-container matColumnDef="qty">
            <th mat-header-cell
                *matHeaderCellDef
                class="txt-center"> Quantidade </th>
            <td mat-cell
                *matCellDef="let row"
                class="txt-center">
                <input mkg-editable
                       matInput
                       money
                       [decimalPoints]="3"
                       [limit]="9999"
                       [noClass]="true"
                       [(ngModel)]="row.qty"
                       (change)="calculateValue()">
            </td>
        </ng-container>

        <!-- osType -->
        <ng-container matColumnDef="osType">
            <th mat-header-cell
                *matHeaderCellDef> Tipo de OS </th>
            <td mat-cell
                *matCellDef="let row">
                <mat-select [(ngModel)]="row.osType"
                            placeholder="Selecione...">
                    <mat-option *ngFor="let roType of roTypes"
                                [value]="roType.id"
                                [title]="roType.observation">
                        {{ roType.type }}
                    </mat-option>
                </mat-select>
            </td>
        </ng-container>

        <!-- active -->
        <ng-container matColumnDef="active">
            <th mat-header-cell
                *matHeaderCellDef
                class="txt-center"> Ativo </th>
            <td mat-cell
                *matCellDef="let row"
                class="txt-center">
                <mat-checkbox [checked]="row.active === 1"
                              (change)="onChangeActive(row, $event.checked)"
                              [disabled]="row.factory === 1"
                              color="primary"></mat-checkbox>
            </td>
        </ng-container>

        <!-- factory -->
        <ng-container matColumnDef="factory">
            <th mat-header-cell
                *matHeaderCellDef
                class="txt-center"> Fábrica </th>
            <td mat-cell
                *matCellDef="let row"
                class="txt-center">
                <mat-checkbox [checked]="[3, 1].includes(row.factory)"
                              (change)="onChangeFactory(row, $event.checked)"
                              [color]="row.factory === 1 ? 'primary' : 'accent'"></mat-checkbox>
            </td>
        </ng-container>


        <!-- actions -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let row; let idx = index">
                <div class="d-flex justify-content-end">
                    <button mat-icon-button (click)="deleteItem(idx)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="packetItemColumns"></tr>
        <tr mat-row
            cdkDrag
            [cdkDragData]="row"
            *matRowDef="let row; columns: packetItemColumns;"></tr>
    </table>

    <p *ngIf="itemsTable.data.length"
       class="txt-end mt-2 mr-3">Valor dos itens: {{ form.value.value | currency: 'BRL' }}</p>

    <div class="txt-center">

        <!-- laborDefault -->
        <mat-form-field class="col-12">
            <mat-label>Adicionar item</mat-label>
            <input matInput
                   #addItemInput
                   [matAutocomplete]="laborDefaultAutocomplete">
            <mat-autocomplete #laborDefaultAutocomplete="matAutocomplete"
                              (optionSelected)="addItem($event)"
                              [displayWith]="AutoUtilities.displayDescription">
                <mat-option *ngFor="let item of allItems | filter: addItemInput.value : ['code', 'description']"
                            [value]="item">
                    {{ item .code }} &mdash; {{ item.description }} &mdash; {{ item.saleValue | currency: "BRL" }}
                </mat-option>
            </mat-autocomplete>
            <mat-hint *ngIf="!allItems.length">
                <small>Sem itens registrados</small>
            </mat-hint>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid"
            [mat-dialog-close]="form.value">Salvar</button>
</mat-dialog-actions>