<h1 matDialogTitle>{{ form.value.id ? 'Atualizar' : 'Adicionar' }} questão</h1>
<mat-dialog-content>

    <form [formGroup]="form">
        <mat-form-field [ngClass]=" (data.useIn === 'checklist') ? 'col-4' : 'col-8' ">
            <mat-label>Título</mat-label>
            <input matInput
            matInput
                  maxlength="200"
                   #questionName
                   (keyup.enter)="onPressEnter()"
                   formControlName="booleanQuestionName">
        </mat-form-field>

        <mat-form-field class="col-4"
                        *ngIf="data.useIn === 'checklist'">
            <mat-label>Grupo</mat-label>
            <input matInput
                   formControlName="group"
                   #groupinput
                   maxlength="200"
                   [matAutocomplete]="questionGroupAutocomplete">
            <mat-autocomplete #questionGroupAutocomplete="matAutocomplete"
                              (optionSelected)="questionName.focus()">
                <mat-option *ngFor="let group of groups"
                            [value]="group">
                    {{ group }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <!-- <mat-form-field >
            <mat-label>Nome</mat-label>
            <input matInput
                   #questionName
                   (keyup.enter)="onPressEnter()"
                   formControlName="booleanQuestionName">
        </mat-form-field> -->

        <mat-form-field class="col-12 col-md-6 col-lg-4">
            <mat-label>Tipo de verificação</mat-label>
            <mat-select placeholder="Selecione"
                        formControlName="questionType">
                <mat-option *ngFor="let type of QUESTION_TYPES"
                            [value]="type">
                    {{ 'PROPERTY.QUESTION_TYPE.' + QUESTION_TYPE[type] | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="form.value.questionType === QUESTION_TYPE.NUMERIC_NIVEL">
            <div>
                <mat-form-field class="col-4">
                    <mat-label>Mínimo</mat-label>
                    <input matInput
                           formControlName="min"
                           money
                           required
                           [decimalPoints]="0"
                           [limit]="form.value.max - form.value.step">
                </mat-form-field>

                <mat-form-field class="col-4">
                    <mat-label>Passo</mat-label>
                    <input matInput
                           formControlName="step"
                           money
                           [decimalPoints]="0"
                           [limit]="form.value.max/2">
                </mat-form-field>

                <mat-form-field class="col-4">
                    <mat-label>Máximo</mat-label>
                    <input matInput
                           formControlName="max"
                           money
                           required
                           [decimalPoints]="0"
                           [limit]="100">
                </mat-form-field>

                <div class="col-12 d-flex"
                     *ngIf="form.value.max">
                    <div class="col-6 p-2">
                        <span class="txt-center">Indicação de cores</span>
                        <ngx-slider class="makena"
                                    [options]="options"
                                    [(value)]="form.value.rangeMaxRed"
                                    [(highValue)]="form.value.rangeMaxYellow"
                                    (valueChange)="changeSliderOptions()"
                                    (highValueChange)="changeSliderOptions()"></ngx-slider>
                    </div>

                    <div class="col-6 p-2">
                        <span class="txt-center">Valor predefinido</span>
                        <ngx-slider class="makena"
                                    [options]="options"
                                    (valueChange)="changeSliderOptions()"
                                    [(value)]="form.value.default"></ngx-slider>
                    </div>
                </div>
            </div>

        </ng-container>

    </form>


    <div *ngIf="form.value.questionType === QUESTION_TYPE.LIST"
         class="d-flex">
        <mat-form-field class="col-6">
            <mat-label>Adicionar opção de resposta</mat-label>
            <input matInput
                   [(ngModel)]="currentResp"
                   [ngModelOptions]="{standalone:true}"
                   maxlength="200"
                   (keyup.enter)="addRespOption($event)">
            <button mat-icon-button
                    matSuffix
                    type="button"
                    color="primary"
                    [disabled]="!currentResp"
                    (click)="addRespOption()">
                <mat-icon>add</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <table mat-table
           [hidden]="!list.data.length"
           [dataSource]="list">
        <ng-container matColumnDef="option">
            <th mat-header-cell
                *matHeaderCellDef> Opção </th>
            <td mat-cell
                *matCellDef="let option">
                {{ option }}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let resp; let index = index"
                class="txt-end">
                <button mat-icon-button
                        color="warn"
                        (click)="removeRespOption(index)"
                        title="Remover opção de resposta">
                    <mat-icon>clear</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row
            *matHeaderRowDef="['option', 'actions']"></tr>
        <tr mat-row
            *matRowDef="let row; columns: ['option', 'actions'];"></tr>
    </table>

</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button
            color="warn"
            [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button
            color="primary"
            [disabled]="form.invalid"
            [mat-dialog-close]="form.value">OK</button>
</mat-dialog-actions>
